import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import {CoreBlockFragment} from "../components/blocks/CoreBlock";  // eslint-disable-line

class Page extends Component {
    render() {
      // console.log(this.props);
      const blocks = buildBlocks(this.props.data.wpPage.blocks, {}, {}, this.props.data.wpPage.id);

      return (
        <Layout
          meta={this.props.data.wpPage.Meta}
          path={this.props.pageContext.pagePath}
          cta={this.props.data.wpPage.PageCTA}
          title={this.props.data.wpPage.title}
          themeOptions={this.props.data.wp.themeOptions}
        >
            {blocks}
        </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: {eq: $id}) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }   
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page
